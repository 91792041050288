//
// Widgets.scss
//

.mini-stats-wid {
  .mini-stat-icon {
    overflow: hidden;
    position: relative;

    &:before, &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 54px;
      background-color: rgba($white, .1);
      left: 16px;
      transform: rotate(32deg);
      top: -5px;
      transition: all 0.4s;
    }

    &::after {
      left: -12px;
      width: 12px;
      transition: all 0.2s;
    }
  }

  &:hover {
    .mini-stat-icon {
      &::after {
        left: 60px;
      }
    }
  }
}



//
// Range slider
//

.ng5-slider {
  .ng5-slider-pointer {
    background-color: $primary !important;
    width: 18px !important;
    height: 18px !important;
    top: -7px !important;
    outline: none !important;

    &:after {
      top: 5px !important;
      left: 5px !important;
    }
  }

  .ng5-slider-active {
    &:after {
      background-color: $white !important;
    }
  }

  .ng5-slider-tick {
    left: -3px !important;
  }

  .ng5-slider-tick-value {
    left: 1px !important;
    top: -25px !important;
  }

  .ng5-slider-tick-legend {
    top: 14px !important;
    text-transform: capitalize;
    font-size: 0.7rem;
  }

  .ng5-slider-selection {
    background: $primary !important;
  }
}
