.dropdown-toggle {
  &:after {
    display: none;
  }
}

.contact-links {
  a {
    color: $body-color;
  }
}

.pointer {
  cursor: pointer;
}

.ngb-datepicker-navigation-select > .custom-select {
  border: 1px solid #f3f3f9 !important;
}

.custom-table-striped {
  > tbody:nth-of-type(#{$table-striped-order}) {
    --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
    color: var(--#{$variable-prefix}table-striped-color);
  }
}

.margin-table-full {
  margin-top: calc((70px * 2) - 1px);
}

.margin-top-base {
  margin-top: 69px;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.custom-pagination {
  ul {
    margin-bottom: 0 !important;
  }
}

.light {
  color: $light;
}

.danger {
  color: $danger;
}

.warning {
  color: $warning;
}

.success {
  color: $success;
}

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.disabled-opacity {
  i, span {
    opacity: 0.4 !important;
  }
}


@keyframes fadein {
  0% {
    opacity: 0.4;
  }
  66% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0.4;
  }
  66% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.modal-content-max {
  max-height: 40vh;
  overflow: scroll;

  &-small-height {
    max-height: 10vh;
  }
}

.help-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #1585ff;
  cursor: pointer;
}

.modal {
  background: rgba(0, 0, 0, .4) !important;
}

.basic-select {
  .ng-select.ng-select-single .ng-select-container {
    height: $input-height !important;
    background-color: white !important;
    box-shadow: none;
    width: auto !important;
    border: $input-border-width solid $input-border-color !important;
    border-radius: 4px !important;
    padding: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;

    .ng-dropdown-panel {
      .ng-option {
        background-color: white !important;
        color: $input-color !important;
        display: flex !important;
        align-items: center !important;

        &.ng-option-marked {
          color: $primary !important;
        }
      }
    }
  }
}

.close-modal-button {
  font-size: 1.4rem;
  padding: 0;

  &:focus {
    border: none !important;
  }

  border: none !important;
}


ul.steps-indicator li:last-child .done .step-indicator {
  background-color: orange !important;
}

.w-90 {
  width: 90%;
}

.w-5 {
  width: 5%;
}

.b-radius-0 {
  border-radius: 0 !important;
}

.br-0 {
  border-right: 0 !important;
}

.w-30 {
  max-width: 30%;
}

.image-detail-eye {
  background: $primary;
  color: white;
  border-color: #495057;

  &:hover {
    background: lighten(#495057, 2%) !important;
    border-color: lighten(#495057, 2%) !important;
  }
}

.no-padding {
  padding: 0 !important;
}

.fit-width {
  width: 1px;
  white-space: nowrap;
}

.wrap-single-cell {
  width: auto !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  white-space: -o-pre-wrap !important;
  word-break: break-all !important;
}

.mb-24 {
  margin-bottom: 24px;
}

.qr-detail-modal {
  img {
    max-width: 100% !important;
  }
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: #1584fe;
}

.table-striped-bg {
  > tbody:nth-of-type(#{$table-striped-order}) > * {
    --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-striped-bg);
    color: var(--#{$variable-prefix}table-striped-color);
  }
}

.no-bg {
  --bs-table-accent-bg: white!important;
  color: var(--bs-table-striped-color);
}

.input-append-btn {
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.min-large-width {
  width: auto!important;
  min-width: 50px!important;
  white-space: nowrap!important;
}

.min-width-25px {
  min-width: 25px;
}

.min-height-35 {
  min-height: 50vh;
  .list-inline {
    position: absolute;
    bottom: 5%;
    right: 20px;
  }
}

.uploadcare--powered-by {
  display: none!important;
}

.uploadcare--widget__text {
  display: inline !important;
  width: 100% !important;
  padding: 0.47rem 0.75rem !important;
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  appearance: none !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  margin-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.uploadcare--widget__button_type_remove {
  border-top-left-radius: 0 !important;
  padding: 0.47rem 0.75rem !important;
  border-bottom-left-radius: 0 !important;
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.remove-radius-left {
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.remove-radius-right {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}

.or-label {
  margin: 0;
  font-weight: 600;
}

.series-tooltip {
  margin-bottom: 0;
  padding: 10px;
  &-title {
    margin-bottom: 5px;
    font-weight: 700;
    color: #1585ff;
  }
  &-subtitle {
    margin-bottom: 5px;
    font-weight: 500;
    color: #74788d;
  }
}

.apexcharts-toolbar {
  z-index: 1!important;
}

.error-message {
  max-width: 75%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.margin-bottom-50 {
  margin-bottom: 50px!important;
}

.height-100 {
  height: 100%;
}


// editor

.ck.ck-toolbar>.ck-toolbar__items {
  flex-direction: row-reverse !important;
}

.ck.ck-editor__editable_inline {
  text-align: left !important;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne,
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  right: 0;
  left: auto !important;
}

.ck.ck-toolbar {
  background-color: $gray-200 !important;
  border-top-left-radius: 5px!important;
  border-top-right-radius: 5px!important;
  border: 1px solid #ced4da!important;
}

.ck.ck-editor__main>.ck-editor__editable {
  border-color: #ced4da!important;
  box-shadow: none !important;
}

.ck.ck-dropdown__panel,
.ck.ck-list {
  background: $dropdown-bg !important;
  border: 1px solid $dropdown-border-color !important;
}

.ck-editor__editable {
  min-height: 210px !important;
  max-height: 210px !important;
  border-color: #ced4da!important;
  border-bottom-left-radius: 5px!important;
  border-bottom-right-radius: 5px!important;
  border-top: none!important;
}

.precondition-editor {
  .ck-editor__editable {
    min-height: 210px !important;
    max-height: 210px !important;
    border-color: #ced4da!important;
    border-bottom-left-radius: 5px!important;
    border-bottom-right-radius: 5px!important;
    border-top: none!important;
  }
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  flex-wrap: wrap !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  background-color: $card-bg !important;
}

.ck.ck-reset_all,
.ck.ck-reset_all * {
  color: $gray-600 !important
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: transparent !important;
  border: 1px solid #ced4da!important;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: $gray-200 !important;
}

.line-height-1-2 {
  line-height: 1.2rem;
}

.text-right {
  text-align: right!important;
}
.precondition-editor-invalid {
  .ck-editor__editable {
    border-color: #D51E58!important;
  }
  .ck.ck-editor__editable_inline {
    border-color: #D51E58!important;
  }
}
